import React from "react"

import { InfoBody, InfoContent } from "./faq"

export default function DesktopLandingBody() {
  return (
    <InfoBody title="Terms of Service">
      <InfoContent>
        Terms of Service - 03.18.20
        <br />
        https://www.mountproxies.com/termsofservice
        <br />
        <br />
        This Terms of Service (TOS) is an agreement made between MountProxies
        (MountProxies) and Customer:
        <br />
        User: Customer is the person or legal business entity which has
        subscribed to the services of MountProxies ("Service" or "Services"). No
        other person is permitted to use the Service, whether by assignment,
        license, permission, or otherwise, on the part of Customer, or those who
        have gained unauthorized access via Customer to use MountProxies's
        Services. Customer is a legal business entity, and only those who are
        authorized employees or agents of Customer may use the Services. Any
        attempt by Customer to permit use by third parties, who are not
        employees and/or agents, is a breach of this TOS. Terms governing
        MountProxies's rights upon the occurrence of a breach is described in the
        paragraph under the heading "Breach".
        <br />
        Term: Customer agrees to a month-to-month term for Services unless
        otherwise agreed to in writing, by electronic mail, by instant
        messaging, or electronically via MountProxies's website. If the term for
        Service is month-to-month, the term is automatically renewed each month,
        unless Customer provides written notice of cancellation based on the
        terms provided below. If Customer has a term other than month-to-month,
        the term is automatically renewed for the same term, unless Customer
        provides written notice of cancellation based on the terms provided
        below.
        <br />
        Service Fees: Fees for Services ordered by Customer shall begin on the
        date of the order following Customer's execution of electronic signature
        after having read and accepted this TOS, and the schedule of fees and
        other information found at MountProxies's website,
        https://www.mountproxies.com ("Website Information"), all of which are
        posted at MountProxies's website at https://www.mountproxies.com.
        <br />
        Cancellation: MountProxies requires, and Customer agrees, a written
        cancellation notice should be made for discontinuance or downgrades of
        Services. Failure to supply the requisite written notice of cancellation
        may result to continuous charges/billing. Notice of written cancellation
        by Customer is to be given via online customer portal located.
        <br />
        Refunds: All Services rendered by MountProxies are refundable within 1
        day / only, except for other instances where MountProxies stated a
        different refund condition. This includes, but is not limited to:
        monthly or other periodic service fees. A written request should be made
        by Customer through email.
        <br />
        Disputes: Customer seeking to resolve any billing error shall open an
        accounting ticket via email within thirty (30) days of an alleged
        billing error via the customer portal located. Without being limited by
        the preceding sentence, Customer agrees not to contest, seek
        reimbursement, chargeback or otherwise seek non-payment or reversal of
        any payment however made, without limitation, via credit card or
        otherwise for Services rendered. Any such action is a breach of this
        TOS. Terms governing MountProxies's rights upon the occurrence of a
        breach is described in the paragraph under the heading "Breach".
        <br />
        Non-Payment: All payments are due in full on the monthly or other
        periodic anniversary date, depending on the duration of Services agreed
        upon. Failure to remit payment for Services under this TOS on the
        monthly or other periodic anniversary date is a breach of this TOS.
        MountProxies may provide Customer with Services following a failure to
        pay, but is not obligated to, for two calendar days, including the
        anniversary date. Failure to remit payment by the third calendar day,
        which includes the anniversary date, shall result in termination of
        Services, without notice, to Customer, if not sooner terminated.
        <br />
        Payment: Any and all payments set forth in this TOS are the sole
        responsibility of Customer. No other person shall be responsible for
        payments or making payments to MountProxies.
        <br />
        Compliance with Laws: Customer agrees to abide by all laws, foreign and
        domestic, local, state, and federal.
        <br />
        Third-party Contents: Customer acknowledges that MountProxies may provide
        third party websites on MountProxies's website, where such websites may
        provide information, videos, music, images, photographs, sounds, or
        other contents. MountProxies does not, directly or indirectly, endorse,
        agree, or otherwise support such websites or their contents. Customer
        agrees not to hold MountProxies liable or responsible for such contents
        or such websites.
        <br />
        Security of Customer Information: Customer understands and agrees that
        MountProxies cannot guarantee, assure, or promise that information or
        data of Customer held by MountProxies, transmitted during use via
        MountProxies's servers (including, without limitation, any and all
        transmissions to and from Customer via MountProxies's servers) or via
        Customer's use of Service, will not be compromised by unauthorized
        access. Without limiting any right of MountProxies as set forth under the
        heading "Indemnity", Customer holds MountProxies harmless in the event of
        any breach of security to Customer's data or information as described in
        this paragraph.
        <br />
        Security of Customer's Internet Protocol ("IP") Address: MountProxies
        cannot guarantee, assure or promise that Customer's IP address may not
        be compromised by third parties.
        <br />
        Age of Customer: Without limiting the provisions under the heading
        "Governing Law", Customer warrants and represents that it has reached
        the age of majority of 18 years of age, or such other age of majority
        based on laws applicable to the Customer's place of residence, and
        further represents and warrants that it has satisfied all other legal
        requirements for entering into contracts, including this TOS. In the
        event Customer has not reached the age of majority, Customer must exit
        MountProxies's website without executing this TOS.
        <br />
        Indemnification: Customer agrees to indemnify, defend (attorney
        representing MountProxies shall be of MountProxies's choosing and paid for
        by Customer), and hold harmless MountProxies, MountProxies's affiliates,
        and each of MountProxies's officers, directors, owners, attorneys,
        accountants, agents, and employees from and against any and all claims,
        demands, liabilities, obligations, losses, damages, penalties, fines,
        punitive damages, amounts in interest, expenses and disbursements of any
        kind and nature whatsoever (including all attorneys' fees) brought by a
        third party against Customer under any theory of legal liability arising
        out of or related to Customer's conduct, in tort, contract, infringement
        of third party's rights of whatever nature or otherwise. Without
        limiting what is set forth under the heading "Assignment", this
        provision under the heading "Indemnification" applies to any person or
        entity, whether or not authorized by Customer, who uses Customer's user
        name, Internet Protocol address, i.e. IP address, or other Customer
        identifying information, to use the Services of MountProxies, Customer
        agrees that MountProxies may, but is not obligated to, at its sole
        discretion, select its own legal counsel, advance attorneys' fees, all
        subject to reimbursement in full by Customer. This indemnification
        provision survives the termination of this
        <br />
        LIMITATION OF LIABILITY: MountProxies shall not be liable for failing to
        provide Service(s) resulting from any condition beyond its reasonable
        control, including, without limitation, governmental actions,
        governmental regulations, any acts of terrorism, armed military
        conflicts, earthquakes, fires, floods, natural disasters, other acts of
        God, labor conditions, power failures, Internet disturbances, and
        failures relating to computers (networks, software, and hardware), and
        telephone network or services. MountProxies shall not be liable to
        Customer for harm caused by or related to Customer's use of Services or
        inability to utilize the Services unless caused by gross negligence or
        willful misconduct on its part. MountProxies shall not be liable for
        temporary unavailability of Services arising from MountProxies making
        maintenance, upgrades, or unavailability for other reasons. MountProxies
        makes no warranties, expressed or implied, including implied warranties
        of title, merchantability, fitness for any particular purpose, or
        non-infringement. MountProxies cannot guarantee and does not promise any
        specific results from use of MountProxies's Services. MountProxies shall
        not be liable to Customer for lost profits (even if informed by
        Customer), direct or indirect, special or incidental, consequential or
        punitive damages, or damages of any kind whether or not known, made
        known to, or should have been known by MountProxies. Notwithstanding
        anything else in this TOS, the maximum aggregate liability of
        MountProxies and any of its employees, agents or affiliates, under any
        theory of law, whether sounding in tort, contract, or otherwise, shall
        not exceed the amount already paid by Customer for Services for the six
        months immediately prior to the occurrence of the event giving rise to
        the claim, provided that Customer has made full payment for that
        six-month period. In the event Customer has not paid for six months of
        Service, whether Customer's use has been of a lesser duration or due to
        a failure to pay, the maximum aggregate liability of MountProxies is
        reduced proportionately. This provision on limitation of liability shall
        survive the termination of this TOS with Customer.
        <br />
        ARBITRATION: Customer agrees that any controversy or claim arising from
        Services provided under or terms related to this TOS, whether sounding
        in tort, contract, or otherwise, shall be resolved by arbitration in
        accordance with the commercial arbitration rules of the American
        Arbitration Association. Venue for arbitration shall be in New
        Hampshire, USA. If the amount in controversy is within the
        jurisdictional amount of the small claims courts of New Hampshire, USA,
        in which case the claim may be brought in the small claims courts of the
        New Hampshire. Customer waives all defenses based on lack of personal
        jurisdiction and forum non conveniens.
        <br />
        Assignment: This TOS may not be assigned by Customer. Any assignment is
        void. Customer is hereby informed that MountProxies may form a business
        entity, including, without limitation, a limited liability company. Upon
        such occurrence, MountProxies shall assign to such entity this TOS,
        Appropriate Use Policy ("AUP"), Website Information, Services, and all
        matters pertaining thereto.
        <br />
        Appropriate Use Policy: MountProxies's AUP, which is posted at
        MountProxies's website at
        https://www.mountproxies.com/acceptibleusepolicy, is incorporated herein
        by reference and made a part hereof. Customer represents and warrants
        that it has read and understood the AUP in its entirety before executing
        this TOS. A breach of that policy is a breach of this TOS. A third-party
        abuse complaint received by the MountProxies linked to a Customer's IP
        addresses will result in a $99 abuse processing fee charged to the
        customer and possible account termination at the MountProxies's sole
        discretion.
        <br />
        Breach: A breach by Customer of any of the terms and provisions of this
        TOS, AUP, or Website Information, whether specifically stated therein as
        a breach, is a breach of this TOS. In the event of Customer's breach,
        MountProxies, at its sole discretion, may terminate the Service
        immediately and seek redress as provided in this TOS. The remedies
        provided in this TOS are in addition to remedies available to
        MountProxies in law and at equity. Without limiting that which is set
        forth under the headings of "Refunds" and "Disputes", in the event of a
        breach by Customer, there shall be no refunds, reimbursements,
        chargebacks, or otherwise.
        <br />
        Decline to Renew or Supply Additional Services: Without limiting any
        other provision in this TOS, MountProxies retains the right, at its sole
        discretion, to decline to supply additional Services to or renew
        Services with Customer.
        <br />
        Waiver of Rights: No failure to exercise, and no delay in exercising, on
        the part of MountProxies, of any right hereunder or at law or in equity
        shall operate as a waiver thereof. No modification or waiver of any
        provision of this TOS or AUP, or Website Information, nor consent to
        departure therefrom, shall be effective unless in writing, by electronic
        mail, by instant messaging, or electronically, and no waiver or
        modification shall extend beyond the particular case and purpose
        involved, and any waiver or modification does not constitute waiver as
        to MountProxies's rights in the future.
        <br />
        Severability: In the event any term or provision in this TOS is found by
        a tribunal of competent jurisdiction to be invalid, the remainder of the
        TOS shall remain in full force and effect.
        <br />
        Headings: Headings are for ease of reference and are not made a part of
        this TOS.
        <br />
        Changes to TOS and other documents: MountProxies reserves the right to
        change at any time, without notice, the terms of this TOS, the AUP, the
        Website Information, or to add additional documents to its website.
        MountProxies will endeavor to provide notice to Customer of any changes
        or additions, but is not obligated to do so. Any notice which may be
        given will be given at customer portal. Customer agrees to keep itself
        informed and to review regularly for any and all changes to this TOS,
        AUP, Website Information or other documents. Customer's continued use of
        the Service following such changes constitutes consent to such changes.
        <br />
        Governing Law: The laws of the State of New Hampshire shall govern the
        interpretation and enforcement of this TOS, without consideration given
        to its conflict of laws.
        <br />
        Entirety of Understandings: This TOS, AUP, and Website Information
        embody are the understandings between MountProxies and Customer.
        <br />
        Read and Understood this TOS: Customer represents and warrants that it
        has read and understood this TOS in its entirety before executing this
        TOS.
        <br />
        Electronic Signature: Customer agrees and accepts the terms and
        provisions of this TOS, AUP, and Website Information by selecting
        "Complete Order" on MountProxies's website. By selecting "Complete Order"
        on MountProxies's website, Customer agrees that it has authorized
        MountProxies to bill Customer for Services.
        <br />
        <br />
        This site is protected by reCAPTCHA and the Google{" "}
        <a href="https://policies.google.com/privacy">
          Privacy Policy
        </a> and{" "}
        <a href="https://policies.google.com/terms">Terms of Service</a> apply.
        <br />
        <br />
      </InfoContent>
    </InfoBody>
  )
}
